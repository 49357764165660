import React from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import Container from "react-bootstrap/Container"

import ContentfulReachContent from "../components/global/ContentfulReachContent"

import { AiOutlineTag, AiOutlineCalendar } from "react-icons/ai"
import { Disqus } from "gatsby-plugin-disqus"
import LatestPosts from "../components/blog/LatestPosts"

export const query = graphql`
  query($slug: String!) {
    article: contentfulArticle(seo: { slug: { eq: $slug } }) {
      id
      articleTitle
      articlePublishedDate(formatString: "DD.MM.YYYY")
      commentsIsAvailable
      articleCategory {
        articleCategoryName
        id
      }
      seo {
        slug
        title
        metaDescription
        pageImage {
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 620) {
            src
          }
        }
      }
      articleContent {
        json
      }
      articleImage {
        title
        fluid(maxWidth: 1280) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed(width: 1920) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
  }
`

const ArticleTemplate = ({ data: { article } }) => {
  const bgImage = [
    article.seo.pageImage.fluid,
    // `background-color(rgba(81, 45, 168, 0.7)) `,
    `radial-gradient(at 50% 100%, rgba(81, 45, 168, 0.7), rgba(15, 1, 94, 0.54))`,
  ].reverse()

  let disqusConfig = {
    url: `${"https://newtelecom.ua/blog/" + article.seo.slug}`,
    identifier: article.id,
    title: article.articleTitle,
  }

  return (
    <Layout>
      <SEO
        title={article.seo.title}
        description={article.seo.metaDescription}
        image={`https:${article.seo.pageImage.fixed.src}`}
        url={`/blog/${article.seo.slug}/`}
      />
      <BackgroundImage fluid={bgImage} className="text-white">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="article-options">
                  Newtelecom
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/blog/" className="article-options">
                  Блог
                </Link>
              </li>
              <li
                className="breadcrumb-item article-options"
                aria-current="page"
              >
                {article.articleTitle}
              </li>
            </ol>
          </nav>
          <h1 className="mb-5">{article.articleTitle}</h1>

          <div className="article-options d-inline-flex align-items-center">
            <span className="article-date mr-5">
              <AiOutlineCalendar /> {article.articlePublishedDate}
            </span>
            <AiOutlineTag />
            <span className="article-categories">
              {article.articleCategory.map((category, index) => (
                <span key={category.id}>
                  {(index ? ", " : "") +
                    category.articleCategoryName.toLocaleLowerCase()}
                </span>
              ))}
            </span>
          </div>
        </Container>
      </BackgroundImage>
      {/* <BackgroundImage Tag="section" fluid={bgImage} className="heroscreen">
        asdasdasdsad aasdasdasd
      </BackgroundImage> */}
      <Container className="my-5 blog-article">
        <ContentfulReachContent contentJSON={article.articleContent.json} />
      </Container>
      {article.commentsIsAvailable ? (
        <Container className="my-5">
          <div className="h4">Залишити коментар</div>
          <Disqus config={disqusConfig} />
        </Container>
      ) : (
        ``
      )}
      <Container className="relevant-blog">
        <LatestPosts currentPageSlug={article.seo.slug} />
      </Container>
    </Layout>
  )
}

export default ArticleTemplate
